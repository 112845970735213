import React from 'react';
import { Button } from 'primereact/button';

const Header = ( {children} ) => {
    const handleScroll = (anchor) => {
        const element = document.getElementById(anchor);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className='w-full dark-gradient text-200'>
            <div className='flex flex-column gap-3 md:gap-0 md:flex-row m-auto w-full p-3 pt-3 md:pt-6 pb-3 md:pb-5 md:w-7'>
                <div className='flex-1 flex justify-content-center md:flex-0 md:justify-content-start'>
                    <img src='widelogolight.png' className='w-15rem' alt='Logo' />
                </div>
                <div className='flex-1 justify-content-center md:justify-content-end flex'>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Header;