import React from 'react';
import ContactForm from './ContactForm';

const ContactBlock = (props) => {
    return (
        <div {...props} className={`w-full ${props.className || ''}`}>
            <div className='flex m-auto w-full p-3 md:p-8 md:pt-5 md:pb-5 md:w-7'>
                <div className='text-900'>
                    <span className='text-2xl font-bold pb-0 inline-block' id='services-anchor'>
                        Take part in the adventure. Contact us
                    </span><br />
                </div>
            </div>
            <div className='flex m-auto w-full p-3 md:p-8 md:pt-0 md:pb-5 md:w-7'>
                <ContactForm />
            </div>
        </div>
    );
};

export default ContactBlock;
