import React, { useState, useEffect } from 'react';

const TypewriterText = ({ texts, typingSpeed = 150, deletingSpeed = 50, delay = 3000 }) => {
    const [currentText, setCurrentText] = useState('');
    const [index, setIndex] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const [cursorVisible, setCursorVisible] = useState(true);

    useEffect(() => {
        // Clignotement du curseur
        const cursorInterval = setInterval(() => {
            setCursorVisible((visible) => !visible);
        }, 500);

        return () => clearInterval(cursorInterval);
    }, []);

    useEffect(() => {
        const fullText = texts[index];
        let timer;

        if (isDeleting) {
            timer = setTimeout(() => {
                setCurrentText((prev) => prev.slice(0, -1));
                if (currentText === '') {
                    setIsDeleting(false);
                    setIndex((prevIndex) => (prevIndex + 1) % texts.length);
                }
            }, deletingSpeed);
        } else {
            timer = setTimeout(() => {
                setCurrentText((prev) => fullText.slice(0, prev.length + 1));
                if (currentText === fullText) {
                    setTimeout(() => setIsDeleting(true), delay);
                }
            }, typingSpeed);
        }

        return () => clearTimeout(timer);
    }, [currentText, isDeleting, index, texts, typingSpeed, deletingSpeed, delay]);

    return (
        <div style={{ /*fontSize: '1rem',*/ display: 'inline-block' }}>
            {currentText}
            {cursorVisible && <span style={{ borderRight: '2px solid', marginLeft: '2px' }} />}
        </div>
    );
};

export default TypewriterText;
