import React from 'react';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className='w-full dark-gradient text-200'>
            <div className='flex flex-column m-auto w-full p-3 md:w-7 font-sans'>
                <div className='flex gap-3 flex-column md:flex-row justify-content-between w-full line-height-4'>
                    <div className='flex-1'>
                        <strong className='text-lg'>Growspective</strong><br />
                        <Link to={'/'} className='' style={{cursor: 'pointer', textDecoration: 'none', color: 'inherit'}}>Home</Link><br />
                        <Link to={'legal'} className='' style={{cursor: 'pointer', textDecoration: 'none', color: 'inherit'}}>Legal notice</Link>
                    </div>
                    <div className='flex-1'>
                        <strong className='text-lg'>Services</strong><br />
                        <Link to={'/consulting'} className='' style={{cursor: 'pointer', textDecoration: 'none', color: 'inherit'}}>360° consulting</Link><br />
                        <Link to={'/webdev'} className='' style={{cursor: 'pointer', textDecoration: 'none', color: 'inherit'}}>Web and mobile development</Link><br />
                        <Link to={'/tech'} className='' style={{cursor: 'pointer', textDecoration: 'none', color: 'inherit'}}>AI & Blockchain</Link><br />
                        <Link to={'/marketing'} className='' style={{cursor: 'pointer', textDecoration: 'none', color: 'inherit'}}>Marketing</Link>
                    </div>
                </div>
                <div className='w-full text-400 mt-3 mb-3 pt-3' style={{borderTop: '1px solid'}} >
                    Copyright © 2024 - Growspective<br />
                    All rights reserved
                </div>
            </div>
        </div>
    );
};

export default Footer;