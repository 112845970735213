import React, { useEffect } from 'react';
import TypewriterText from './TypewriterText';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import CustomLink from './CustomLink';

const Home = ( {setHeaderLinks, handleScroll} ) => {
    useEffect(() => {
        if (setHeaderLinks && handleScroll)
        setHeaderLinks(<>
            <Button label='Services' className='p-button-text text-200' onClick={()=>handleScroll('services-anchor')} />
        </>);

        return () => {
            if (setHeaderLinks)
            setHeaderLinks(null);
        };
    }, []);

    return (
        <div>
            <div className='w-full dark-gradient text-200'>
                <div className='flex m-auto w-full p-3 pb-6 md:w-7'>
                    <div className='text-xl'>
                        We help you&nbsp;
                        <span style={{color: '#aaffaa'}} className='block md:inline-block'>
                            <TypewriterText texts={['make your ideas come true', 'build an online presence', 'succeed', 'develop your potential', 'benefit of the technologies of the 21st century']} />
                        </span>
                    </div>
                </div>
            </div>

            <div className='w-full'>
                <div className='flex m-auto w-full p-3 md:p-8 md:pt-5 md:pb-5 md:w-7'>
                    <div className='text-900'>
                        <span className='text-2xl font-bold pb-3 inline-block' id='services-anchor'>Your project ? Our expertise.</span><br />
                        <span>
                            We offer a complete range of services to help you
                            develop your project. We will accompany you throughout the whole process, from the idea to the final product.
                        </span>
                    </div>
                </div>
                <div className='service-card-container flex flex-row gap-3 flex-wrap m-auto w-full p-3 pb-6 md:w-9 font-sans'>
                    <Card className='flex-1' title='360º support in your projects' header={<img src='360viz.webp' alt='360º support' />}>
                        We offer a wide range of <CustomLink target={'/consulting'}>consulting</CustomLink> services to help you achieve your goals.
                    </Card>
                    <Card className='flex-1' title='Custom development' header={<img src='dev.webp' alt='Custom development' />}>
                        We develop custom solutions to meet your needs, from the simplest to the most complex.
                        We have expertise in <CustomLink target={'/webdev'}>web development</CustomLink>, <CustomLink target={'/webdev'}>mobile development</CustomLink>, and more.
                    </Card>
                    <Card className='flex-1' title='Emerging technologies' header={<img src='ai.webp' alt='Emerging technologies' />}>
                        Stay ahead of the competition by using the latest technologies such as <CustomLink target={'/tech'}>artificial intelligence</CustomLink> and <CustomLink target={'/tech'}>blockchain</CustomLink>.
                    </Card>
                    <Card className='flex-1' title='Digital marketing' header={<img src='marketing.webp' alt='Digital marketing' />}>
                        We help you develop your marketing strategies through <CustomLink target={'/marketing'}>SEO</CustomLink> and <CustomLink target={'/marketing'}>social networks</CustomLink>.
                    </Card>
                </div>
            </div>

            <div className='w-full dark-gradient text-200'>
                <div className='flex m-auto w-full p-3 md:p-8 md:pt-5 md:pb-5 md:w-7'>
                    <div className='text-xl font-bold'>
                        We provide our solutions to&nbsp;
                        <span style={{color: '#55afdd '}}>
                            <TypewriterText texts={['small businesses', 'artists', 'startups']} />
                        </span>
                    </div>
                </div>
                <div className='flex m-auto w-full p-3 md:p-8 pt-0 md:pt-0 md:pb-5 md:w-7'>
                    <div className=''>
                        Why not you ?<br />
                        Whatever stage your project is at, we can support you.
                    </div>
                </div>

                {/*<div className='service-card-container flex flex-row gap-3 flex-wrap m-auto w-full p-3 pb-6 md:w-9 font-sans'>
                    <Card className='flex-1' header={<img src='360viz.webp' alt='360º support' />}>
                        We offer a wide range of <span className='highlight'>consulting</span> services to help you achieve your goals.
                    </Card>
                    <Card className='flex-1' title='Custom development' header={<img src='dev.webp' alt='Custom development' />}>
                        We develop custom solutions to meet your needs, from the simplest to the most complex.
                        We have expertise in <span className='highlight'>web development</span>, <span className='highlight'>mobile development</span>, and more.
                    </Card>
                    <Card className='flex-1' title='Emerging technologies' header={<img src='ai.webp' alt='Emerging technologies' />}>
                        Stay ahead of the competition by using the latest technologies such as <span className='highlight'>artificial intelligence</span> and <span className='highlight'>blockchain</span>.
                    </Card>
                    <Card className='flex-1' title='Digital marketing' header={<img src='marketing.webp' alt='Digital marketing' />}>
                        We help you develop your marketing strategies through <span className='highlight'>SEO</span> and <span className='highlight'>social networks</span>.
                    </Card>
                </div>*/}
            </div>
        </div>
    );
};

export default Home;