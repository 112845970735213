import React from 'react';
import ArticleTemplate from '../ArticleTemplate';

const LegalNotice = () => {
    return (
        <ArticleTemplate
            title='Legal Notice'
            subtitle='Important information regarding the use of this website'>
            <p>
                This legal notice applies to all users of this website. By accessing and using our site, you agree to the following
                terms and conditions. Please read these terms carefully to ensure a clear understanding of your rights and responsibilities
                when using our services.
            </p>
            <strong>1. Site Ownership</strong>
            <p>
                This website is owned and operated under the commercial name 'Growspective', registered as a micro-entreprise in France under SIREN n°934819079 by Aurélien Auclair.
                All content on this site, including text, images, and graphics, is the property of Growspective and is protected by copyright laws.
            </p>
            <strong>2. Intellectual Property</strong>
            <p>
                All content, trademarks, and logos displayed on this website are the intellectual property of Growspective or its licensors.
                You are not permitted to reproduce, distribute, modify, or publicly display any part of this content without prior written permission.
            </p>
            <strong>3. Disclaimer of Liability</strong>
            <p>
                The information provided on this website is for general informational purposes only and is not intended as legal, financial,
                or professional advice. Growspective is not responsible for any errors or omissions, or for the results obtained from the use
                of this information. Use of this site is at your own risk.
            </p>
            <strong>4. External Links</strong>
            <p>
                This website may contain links to external websites. These links are provided for convenience and do not constitute an endorsement.
                Growspective has no control over the content of linked sites and assumes no responsibility for their content or practices.
            </p>
            <strong>5. Privacy Policy</strong>
            <p>
                We value your privacy. Please review our Privacy Policy for details on how we collect, use, and protect your personal information.
                By using this website, you consent to our collection and use of personal data as outlined in the Privacy Policy.
            </p>
            <strong>6. Changes to This Legal Notice</strong>
            <p>
                Growspective reserves the right to modify this legal notice at any time. Changes will be effective immediately upon
                posting on this page, and we encourage you to review this page periodically to stay informed of your rights and obligations.
            </p>
            <p>
                For any questions or concerns about this legal notice, please contact us using our contact form.
            </p>
        </ArticleTemplate>
    );
};

export default LegalNotice;
